window.addEventListener('load', () => {
  document.querySelectorAll('.discipline-nav').forEach((nav) => {
    const headerElement = nav.querySelector('#discipline-navbar-header');
    let jumpToSection = headerElement.querySelector('span');
    const navElement = nav.querySelector('#disciplineNav');

    navElement.addEventListener('hide.bs.collapse', function () {
      headerElement.querySelectorAll('uxt-icon')[0].setAttribute('icon', 'chevron-down');
      document.body.classList.remove('overflow-hidden');
    });

    navElement.addEventListener('show.bs.collapse', () => {
      headerElement.querySelectorAll('uxt-icon')[0].setAttribute('icon', 'chevron-up');
      document.body.classList.add('overflow-hidden');
      if (nav.getBoundingClientRect().top > 0) nav.scrollIntoView();
    });

    nav.querySelectorAll('.discipline-nav-item a').forEach((navItem) => {
      navItem.addEventListener('click', () => {
        let newHeader = document.createElement('span');
        newHeader.innerHTML = navItem.innerHTML;
        newHeader.classList = navItem.classList;
        newHeader.classList.add('chosenDiscipline');
        jumpToSection.replaceWith(newHeader);
        jumpToSection = newHeader;
        if (headerElement.getAttribute('aria-expanded') == 'true') bootstrap.Collapse.getInstance(navElement).hide();
      });
    });
  });
});
