document.addEventListener('DOMContentLoaded', () => {
    const sharpenTitleCardModal = document.getElementById('sharpenTitleCardModal');
    if(sharpenTitleCardModal) {
        sharpenTitleCardModal.addEventListener('show.bs.modal', event => {
            const button = event.relatedTarget;
            
            const imageUrl = button.dataset.imageUrl;
            const title = button.dataset.title;
            const author = button.dataset.author;
            const badge = button.dataset.badge === 'true';
    
            const card = sharpenTitleCardModal.querySelector('.card');
            const imageEl = card.querySelector('img');
            const titleEl = card.querySelector('.card-title');
            const authorEl = card.querySelector('.card-author');
            const badgeEl = card.querySelector('.badge');
    
            imageEl.setAttribute("src", imageUrl);
            imageEl.setAttribute("alt", title);
            titleEl.textContent = title;
            authorEl.textContent = author
            if(badge)
                badgeEl.classList.remove("d-none");
            else
                badgeEl.classList.add("d-none");
        });
    }
});